'use client'

import i18next from 'i18next'
import {
  initReactI18next,
  useTranslation as useTranslationOrg,
} from 'react-i18next'
import useGlobalContext from 'app/components/GlobalContext/useGlobalContext'
import { DEFAULT_NAMESPACE, getiI18nextOptions } from './settings'

i18next.use(initReactI18next).init(getiI18nextOptions())

export default function useTranslation() {
  const { locale } = useGlobalContext()
  return useTranslationOrg(DEFAULT_NAMESPACE, {
    lng: locale?.split('-')[0] || 'en',
  })
}
